import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {take} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Objecttype} from 'src/app/models/Objecttype';
import {ExcelService} from 'src/app/services/excel.service';
import {ObjectService} from 'src/app/services/object.service';
import {SharedService} from 'src/app/services/shared.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-new-objects-multiple',
  templateUrl: './new-objects-multiple.component.html',
  styleUrls: ['./new-objects-multiple.component.css'],
})
export class NewObjectsMultipleComponent implements OnInit {
  // private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    // no: new FormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    desc: new UntypedFormControl('', []),
    type: new UntypedFormControl('', [Validators.required]),
    process: new UntypedFormControl('', []),
  });
  @Input() data: any[];
  dataFrontend: any[];
  objecttypes: Objecttype[];
  isLoading = true;
  errormessage = '';
  objectnotfound = 0;

  constructor(
    private sharedservice: SharedService,
    private snackbarservice: SnackbarService,
    private objectservice: ObjectService,
    private excelservice: ExcelService,
    public dialogRef: MatDialogRef<NewObjectsMultipleComponent>
  ) {
  }

  get no() {
    return this.Form.get('no');
  }

  get name() {
    return this.Form.get('name');
  }

  get desc() {
    return this.Form.get('desc');
  }

  get type() {
    return this.Form.get('type');
  }

  get process() {
    return this.Form.get('process');
  }

  async ngOnInit(): Promise<void> {
    // console.log(this.data);
    console.log(this.data);
    const objects = await firstValueFrom(this.sharedservice.objects.pipe(take(1)));
    const types = await firstValueFrom(this.sharedservice.objecttypes.pipe(take(1)));
    if (types !== null) {
      // console.log(types);
      for (const d of this.data) {
        if (d.type && !d.type.id) {
          for (const type of types) {
            if (type.name === d.type) {
              d.type = type;
              // {
              //   id: type.id,
              //   name: type.name,
              // }
            }
          }
          if (!d.type?.id) {
            d.type = null;
          }
        }
        if (d.objectid) {
          d.change = 'error';
          for (const object of objects) {
            if (d.objectid === object.id) {
              d.change = '';
              if (d.name !== object.name) {
                d.old_name = object.name || '';
                d.change = 'change';
              }
              if (d.process !== object.process) {
                d.old_process = object.process || '';
                d.change = 'change';
              }
              if (d.type?.name !== object.type?.name) {
                d.old_type = object.type?.name || '';
                d.change = 'change';
              }
              if (d.desc !== object.desc) {
                d.old_desc = object.desc || '';
                d.change = 'change';
              }
              if (d.profilenumber !== object.profilenumber) {
                d.old_profilenumber = object.profilenumber || '';
                d.change = 'change';
              }
              if (d.drawing !== object.drawing) {
                d.old_drawing = object.drawing || '';
                d.change = 'change';
              }
              if (d.comment !== object.comment) {
                d.old_comment = object.comment || '';
                d.change = 'change';
              }
              // console.log(d)
            }
          }
        } else {
          d.change = 'new';
        }
        if (d.change === 'error') {
          this.objectnotfound++;
        }
      }
      this.objecttypes = types.filter(type => type.category?.id !== 'loJgvvKxjzx1jk771Wuf'); // Filter out Mottak category
      console.log(this.objecttypes);
      this.dataFrontend = structuredClone(this.data);
      this.isLoading = false;
    }
  }

  async saveObjects() {
    console.log(this.data);
    const objects: AventiObject[] = [];
    let readyToSave = true;
    for (const o of this.data) {
      if (!o.type?.id) {
        readyToSave = false;
      }
      if (!o.name) {
        readyToSave = false;
      }
      objects.push({
        id: o.objectid || null,
        name: o.name || '',
        process: o.process || '',
        type: o.type || '',
        desc: o.desc || '',
        profilenumber: o.profilenumber || '',
        drawing: o.drawing || '',
        comment: o.comment || '',
      });
    }
    console.log(objects);
    if (readyToSave) {
      this.errormessage = '';
      this.sharedservice.isLoading.next([
        true,
        'Vennligst vent...',
        ['Importerer ' + this.data.length + ' objekter...'],
      ]);
      const message = await this.objectservice.setArray(objects);
      console.log(message);
      this.dialogRef.close();
      // window.location.reload();
    } else {
      this.snackbarservice.openSnackBar('Fyll ut alle objekttyper', '');
      this.errormessage = 'Fyll ut alle objekttyper';
    }
  }

  downloadChangeInObjects() {
    this.excelservice.exportAsExcelFile(this.data, 'endringslogg');
  }

}
