import {MediaMatcher} from '@angular/cdk/layout';
import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationStart, Params, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {ChecklistService} from 'src/app/services/checklist.service';
import {DateService} from 'src/app/services/date.service';
import {ObjectService} from 'src/app/services/object.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import {Table} from 'primeng/table';

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import firebase from 'firebase/compat/app';
import {saveAs} from 'file-saver';
import {FilterMetadata, SortMeta} from 'primeng/api';
import {SortButton} from 'src/app/models/PrimeNG';
import {TableService} from 'src/app/services/prime/table.service';
import {PopupService} from 'src/app/services/popup.service';
import {IdService} from 'src/app/services/id.service';
import {ConfirmComponent} from '../../common/bottom-sheet/confirm/confirm.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {SnackbarService} from '../../../services/snackbar.service';

@Component({
  selector: 'app-checklist-list',
  templateUrl: './checklist-list.component.html',
  styleUrls: ['./checklist-list.component.css'],
  providers: [ObjectService],
})
export class ChecklistListComponent
  implements OnInit, OnDestroy, AfterViewInit {

  static progressstring: string[] = [];
  disableRoute = false;
  @Input() type = 'checklist';
  isEmpty = false;
  objects: AventiObject[];
  checklists: Checklist[];
  checklistsFrontend: Checklist[];
  user: User;
  checklistpdfs: AventiFile[];
  checklistpdfsnotsent: AventiFile[] = [];
  pdfsLoaded: boolean;
  isDownoadingPDF: boolean;
  mobileQuery: MediaQueryList;
  isLoading = true;

  @ViewChild(Table) dataTable: Table;
  selectedRows: Checklist[] = [];
  tableFiltersReady = false;
  pauseSorting = false;
  resetTable = false;
  numberOfTableSorts = 0;
  numberOfTableFilters = 0;
  tableSort: SortMeta[];
  tableFilters: { [p: string]: FilterMetadata } = {};
  sortButtons: SortButton[] = [];
  filterFields: string[] = [];
  displayedColumns: string[] = [];
  searchString = '';

  statuses: any[] = [];
  processes: any[] = [];
  types: any[] = [];
  profilenumbers: any[] = [];

  pageIndex = 0;
  pageSize = 25;
  page = 1;
  pages: number;
  numberOfSentToCustomer = 0;
  numberOfNotSentToCustomer = 0;
  numberOfNotGenerated: number;
  numberOfDeviations: number;
  class: any;
  params: Params;
  isOnline: boolean;
  projectid: string;
  private ngUnsubscribe = new Subject();
  @ViewChild('dataTable') dt: Table;

  constructor(
    private checklistservice: ChecklistService,
    media: MediaMatcher,
    private router: Router,
    private sharedservice: SharedService,
    private dateservice: DateService,
    private ptableService: TableService,
    private popupservice: PopupService,
    private idservice: IdService,
    private bottomSheet: MatBottomSheet,
    private snackbarservice: SnackbarService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    this.class = ChecklistListComponent;
  }

  ngAfterViewInit() {
    this.buildChecklists();
    // tslint:disable-next-line:only-arrow-functions
    this.dt.resetScrollTop = function() {
    };
  }

  ngOnInit(): void {

    if (this.type === 'receiptform') {
      this.sortButtons = [
        {name: 'Navn', sort: {field: 'nameSearchString', order: 0}},
        {name: 'Beskrivelse', sort: {field: 'desc', order: 0}},
        {name: 'Kommentar', sort: {field: 'comment', order: 0}},
        {name: 'Tilhørende objekt', sort: {field: 'receiptFormObjects', order: 0}},
        {name: 'Status', sort: {field: 'status', order: 0}},
        {name: 'Levert kunde', sort: {field: 'PDFDeliveredToCustomerBoolean', order: 0}},
        {name: 'Antall avvik', sort: {field: 'numberOfDeviations', order: 0}},
        // {name: 'PDF', sort: {field: 'pdfexportsBoolean', order: 0}}
      ];
    } else {
      this.sortButtons = [
        {name: 'Prosess', sort: {field: 'process', order: 0}},
        {name: 'Type', sort: {field: 'typename', order: 0}},
        {name: 'Objektnavn', sort: {field: 'nameSearchString', order: 0}},
        {name: 'Beskrivelse', sort: {field: 'desc', order: 0}},
        {name: 'Kommentar', sort: {field: 'comment', order: 0}},
        {name: 'Profilnummer', sort: {field: 'profilenumber', order: 0}},
        {name: 'Status', sort: {field: 'status', order: 0}},
        {name: 'Levert kunde', sort: {field: 'PDFDeliveredToCustomerBoolean', order: 0}},
        {name: 'Antall avvik', sort: {field: 'numberOfDeviations', order: 0}},
        // {name: 'PDF', sort: {field: 'pdfexportsBoolean', order: 0}}
      ];
    }

    this.projectid = this.idservice.projectId();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(async () => {
        if (!this.resetTable) {
          this.callSetTableSortAndFilterFromQueryParams();
        }
      });

    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          if (user[0] && !this.user) {
            this.user = user[0];
            this.buildChecklists();
          }
        }
      });

    let datasourceObs: Observable<Checklist[]>;
    if (this.type === 'receiptform') {
      datasourceObs = this.sharedservice.receiptforms;
    } else {
      datasourceObs = this.sharedservice.checklists;
    }
    datasourceObs
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklists: Checklist[]) => {
        if (checklists !== null) {
          this.checklists = checklists;
          this.buildChecklists();
        }
      });

    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects) => {
        if (objects !== null) {
          this.objects = objects;
          // this.buildChecklists();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  buildChecklists() {
    if (this.user && this.checklists) {
      this.numberOfDeviations = 0;
      if (this.objects) {
        this.numberOfNotGenerated =
          this.objects.length - this.checklists.length;
      }

      const checklistarray: Checklist[] = [];
      const checklistarrayAll: Checklist[] = [];
      this.numberOfSentToCustomer = 0;
      this.numberOfNotSentToCustomer = 0;
      this.checklistpdfsnotsent = [];

      this.checklists.forEach(checklist => {

        checklist.typename = checklist.type?.name;
        checklist.nameSearchString = checklist.name + checklist.desc + checklist.comment;

        let objectsSearchString = '';
        checklist.objects?.map(object => {
          objectsSearchString += object.name;
        });
        checklist.objectsSearchString = objectsSearchString;

        let tagsSearchString = '';
        checklist.tags?.map(tag => {
          tagsSearchString += tag.key + tag.value;
        });
        checklist.tagsSearchString = tagsSearchString;

        const newStaus = {
          label: checklist.statusHuman,
          value: checklist.status,
          count: 0
        };
        if (!this.statuses.some(item => item.label === checklist.statusHuman || item.value === checklist.status)) {
          this.statuses.push(newStaus);
        }

        const newProcess = {
          label: checklist.process,
          value: checklist.process
        };
        if (checklist.process?.length > 0 &&
          !this.processes.some(item => item.label === checklist.process || item.value === checklist.process)) {
          this.processes.push(newProcess);
        }

        const newType = {
          label: checklist.type?.name,
          value: checklist.type?.name
        };
        if (checklist.type?.name?.length > 0 &&
          !this.types.some(item => item.label === checklist.type?.name || item.value === checklist.type?.name)) {
          this.types.push(newType);
        }

        const newProfileNumber = {
          label: checklist.profilenumber,
          value: checklist.profilenumber
        };
        if (checklist.profilenumber?.length > 0 &&
          !this.profilenumbers.some(item => item.label === checklist.profilenumber || item.value === checklist.profilenumber)) {
          this.profilenumbers.push(newProfileNumber);
        }

        if (checklist.PDFDeliveredToCustomer) {
          checklist.PDFDeliveredToCustomer.pending = false;
        }
        if (checklist.pdfexports) {
          const regex = new RegExp(`.[^. ]* `, 'g');
          checklist.pdfexports.forEach((pdf) => {
            if (pdf.sizestring) {
              pdf.sizestring = pdf.sizestring.replace(regex, '');
            }
          });
          this.pdfsLoaded = true;
        }
        checklist.pdfexportsBoolean = checklist.pdfexports?.length > 0;

        this.numberOfDeviations += checklist.numberOfDeviations;

        if (this.type === 'receiptform') {
          if (checklist.receiptform) {
            if (
              checklist.status === 'signed' &&
              this.user.role.name === 'customer'
            ) {
              checklistarray.push(checklist);
            } else if (this.user.role?.name !== 'customer') {
              checklistarray.push(checklist);
            }
            checklistarrayAll.push(checklist);
          }
        } else {
          if (!checklist.receiptform) {
            if (
              checklist.status === 'signed' &&
              this.user.role.name === 'customer'
            ) {
              if (!checklist.PDFDeliveredToCustomer?.status) {
                checklistarray.push(checklist);
                checklist.pdfexports?.forEach((pdf) => {
                  if (!pdf.checklistcomment) {
                    pdf.checklistcomment = checklist.comment
                      ? checklist.comment
                      : '';
                  }
                  this.checklistpdfsnotsent.push(pdf);
                  this.numberOfNotSentToCustomer++;
                });
              } else {
                this.numberOfSentToCustomer++;
                checklistarray.push(checklist);
              }
            } else if (this.user.role.name !== 'customer') {
              if (!checklist.PDFDeliveredToCustomer?.status) {
                checklistarray.push(checklist);
                checklist.pdfexports?.forEach((pdf) => {
                  if (!pdf.checklistcomment) {
                    pdf.checklistcomment = checklist.comment
                      ? checklist.comment
                      : '';
                  }
                  this.checklistpdfsnotsent.push(pdf);
                  this.numberOfNotSentToCustomer++;
                });
              } else {
                this.numberOfSentToCustomer++;
                checklistarray.push(checklist);
              }
            }
            checklistarrayAll.push(checklist);
          }
        }
      });

      this.statuses.forEach(status => {
        status.count = this.checklists.filter(cl => cl.status === status.value).length;
      });

      const checklistsWithBlanks: Checklist[] = [];

      checklistarray.map(checklist => {
        const emptyChecklist: Checklist = new Checklist();
        Object.entries(checklist).forEach(([key, value]) => {
          emptyChecklist[key] = value;
        });
        emptyChecklist.PDFDeliveredToCustomerBoolean =
          (checklist.status === 'signed' && checklist.pdfexports?.length > 0) ?
            !!emptyChecklist.PDFDeliveredToCustomer?.status : null;
        emptyChecklist.numberOfDeviations = emptyChecklist.numberOfDeviations > 0 ? emptyChecklist.numberOfDeviations : null;

        checklistsWithBlanks.push(emptyChecklist);
      });

      this.setDisplayedColumns();
      this.checklistsFrontend = structuredClone(checklistsWithBlanks);

      this.isLoading = false;
      this.callSetTableSortAndFilterFromQueryParams();
    }
  }

  setDisplayedColumns() {
    if (this.mobileQuery.matches) {
      if (this.user.role?.name === 'customer') {
        this.displayedColumns = ['mobileName', 'mobileStatus'];
      } else {
        if (this.type === 'receiptform') {
          this.displayedColumns = [
            'mobileNameReceiptform',
            // 'objects',
            'mobileStatus',
          ];
        } else {
          this.displayedColumns = [
            'mobileName',
            // 'mobileStatus',
            // 'tags',
            'checklistpdfs',
            'pdfdeliveredToCustomer',
          ];
        }
      }
    } else {
      if (this.user.role?.name === 'customer') {
        this.displayedColumns = [
          'name',
          'profilenumber',
          'type',
          'tags',
          'comment',
          'deviations',
          'statusHuman',
        ];
      } else {
        if (this.type === 'receiptform') {
          this.displayedColumns = [
            'name',
            'objects',
            'comment',
            'deviations',
            'statusHuman',
            'pdfdate',
            'lastupdated',
          ];
        } else {
          this.displayedColumns = [
            'process',
            'type',
            'name',
            'desc',
            'profilenumber',
            'tags',
            'comment',
            'deviations',
            'statusHuman',
            'checklistpdfs',
            'pdfdeliveredToCustomer',
            'pdfdate',
            'lastupdated',
          ];
        }
      }
    }
    this.filterFields = this.displayedColumns;
    this.filterFields.push('nameSearchString', 'objectsSearchString', 'typename', 'status', 'statusSearchString', 'PDFDeliveredToCustomerBoolean', 'numberOfDeviations', 'receiptFormObjects');
  }

  setDeliveredToCustomer(checklist: Checklist) {
    let s = true;
    if (checklist.PDFDeliveredToCustomer) {
      checklist.PDFDeliveredToCustomer.pending = true;
      if (checklist.PDFDeliveredToCustomer.status) {
        s = false;
      }
    } else {
      checklist.PDFDeliveredToCustomer = {status: false, pending: true};
    }
    this.checklistservice.updateChecklistDeliveredPdfToCustomer(
      s,
      checklist,
      this.user
    );
  }

  toggleDisableRoute() {
    this.disableRoute = true;
    setTimeout(() => this.disableRoute = false, 100);
  }

  urlToPromise(url: string) {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  downloadAllPdfsNotSent() {
    this.isDownoadingPDF = true;
    const timestamp = this.dateservice.dateAsYYYYMMDDHHNNSS(
      firebase.firestore.Timestamp.now()
    );
    const zipfilename = timestamp + '_PDF_not_sent';
    const zip = new JSZip();
    let index = 0;
    this.checklistpdfsnotsent.forEach((file) => {
      this.urlToPromise(file.downloadurl).then((data: any) => {
        zip.file(file.name, data, {binary: true});
        index++;
        this.class.progressstring[0] =
          'Legger til PDF (' +
          index +
          '/' +
          this.checklistpdfsnotsent.length +
          ' stk)';
        if (index === this.checklistpdfsnotsent.length) {
          console.log('Ready to generate zip file');
          zip
            .generateAsync({type: 'blob'}, metadata => {
              const msg = 'Progression : ' + metadata.percent.toFixed(0) + ' %';
              console.log(msg);
              ChecklistListComponent.progressstring[0] = `Genererer zip fil... ${metadata.percent.toFixed(
                0
              )}%`;
            })
            .then(
              blob => {
                // see FileSaver.js
                console.log('saveas', zipfilename);
                saveAs(blob, zipfilename + '.zip');
              },
              e => {
                console.error(e);
                zip.generateAsync({type: 'base64'}).then(
                  base64 => {
                    console.log('anchor', zipfilename);
                    const anchor = document.createElement('a');
                    anchor.download = zipfilename + '.zip';
                    anchor.href = 'data:application/zip;base64,' + base64;
                    anchor.target = '_blank';
                    anchor.click();
                    anchor.remove();
                  },
                  err => {
                    console.error(err);
                  }
                );
              }
            )
            .then(() => {
              this.isDownoadingPDF = false;
              this.class.progressstring = [];
            });
        }
      });
    });
  }

  onSort(event) {
    if (this.tableFiltersReady && !this.pauseSorting && !this.resetTable) {
      const sortArray: SortMeta[] = event.multisortmeta;
      this.tableSort = sortArray;
      this.sortButtons =
        this.ptableService.onSort(sortArray, this.sortButtons, this.tableSort, this.tableFilters, this.tableFiltersReady);
    }
  }

  sortFromButton(button: SortButton) {
    this.pauseSorting = true;
    const {
      tableSort,
      numberOfTableSorts,
      sortButtons
    } = this.ptableService.sortFromButton(
      button,
      this.sortButtons,
      this.tableSort,
      this.tableFilters,
      this.tableFiltersReady,
      this.numberOfTableSorts
    );
    this.tableSort = tableSort;
    this.numberOfTableSorts = numberOfTableSorts;
    this.sortButtons = sortButtons;
    this.pauseSorting = false;
  }

  clearTable(table: Table) {
    this.resetTable = true;
    this.searchString = '';
    const {
      tableSort,
      numberOfTableFilters,
      numberOfTableSorts,
      sortButtons
    } = this.ptableService.clearTable(table, this.tableSort, this.sortButtons);
    this.tableSort = tableSort;
    this.numberOfTableFilters = numberOfTableFilters;
    this.numberOfTableSorts = numberOfTableSorts;
    this.sortButtons = sortButtons;
    this.resetTable = false;
  }

  resetTableSort() {
    const {
      tableSort,
      sortButtons,
      tableFilters,
      numberOfTableFilters,
      numberOfTableSorts,
      searchString
    } = this.ptableService.resetTableSort(
      this.tableSort,
      this.sortButtons,
      this.tableFilters,
      this.tableFiltersReady,
      this.tableFilters
    );
    this.tableSort = tableSort;
    this.sortButtons = sortButtons;
    this.tableFilters = tableFilters;
    this.numberOfTableFilters = numberOfTableFilters;
    this.numberOfTableSorts = numberOfTableSorts;
    this.searchString = searchString;
  }

  onFilter(event: any) {
    if (this.tableFiltersReady && !this.resetTable) {
      const eventFilters: { [p: string]: FilterMetadata } = event.filters;
      this.numberOfTableFilters = this.ptableService.setQueryParamsFromTableEvents(
        this.tableSort,
        this.tableFiltersReady,
        eventFilters
      );
      this.statuses.forEach(status => {
        status.count = this.checklists.filter(cl => cl.status === status.value && event.filteredValue.some(v => v.id === cl.id)).length;
      });
    }
  }

  toggleStatusFilter(value: string) {
    const newTableFilter = {...this.tableFilters};
    let newValue = newTableFilter.status?.value ?? null;
    if (newValue?.includes(value)) {
      const index = newValue.indexOf(value);
      newValue.splice(index, 1);
      if (newValue.length === 0) {
        newValue = null;
      }
    } else {
      if (newValue === null) {
        newValue = [value];
      } else {
        newValue.push(value);
      }
    }
    if (newTableFilter.status) {
      newTableFilter.status.value = newValue;
    }
    if (newValue !== null) {
      newTableFilter.status.operator = 'and';
    } else {
      delete newTableFilter.status.operator;
    }
    this.dt.filter(newValue, 'statusSearchString', 'in');
    this.tableFilters = newTableFilter;
    this.dt.filters = newTableFilter;
  }

  getSeverity(status: string) {
    return this.ptableService.getChecklistStatusSeverity(status);
  }

  onRowSelect(event: any) {
    if (this.mobileQuery.matches) {
      this.ptableService.openChecklist(event.data, this.disableRoute, this.type);
    }
  }

  onRowUnSelect(event: any) {
    if (!this.mobileQuery.matches) {
      this.ptableService.openChecklist(event.data, this.disableRoute, this.type);
    }
  }

  newReceiptform() {
    // this.receiptformservice.openDialog();
    this.popupservice.openDialogNewReceiptForm(this.user, this.projectid);
  }

  private callSetTableSortAndFilterFromQueryParams() {
    const result = this.ptableService.setTableSortAndFilterFromQueryParams(
      this.tableSort,
      this.sortButtons,
      this.tableFilters,
      this.tableFiltersReady
    );
    if (!result?.tableFiltersReady) {
      return setTimeout(() => {
        this.tableFiltersReady = true;
        this.callSetTableSortAndFilterFromQueryParams();
      }, 1000);
    } else if (result) {
      this.tableSort = result.tableSort;
      this.sortButtons = result.sortButtons;
      this.tableFilters = result.tableFilters;
      this.numberOfTableFilters = result.numberOfTableFilters;
      this.numberOfTableSorts = result.numberOfTableSorts;
      this.searchString = result.searchString;
    }
  }

  async deleteMultipleChecklists() {

    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: `Slette sjekklister?`,
        text: `${this.selectedRows.length} sjekklister vil bli slettet:`,
        list: this.selectedRows.map((checklist) => checklist.name),
        yes: {
          name: 'Slett',
          icon: 'delete_forever',
        },
        no: {
          name: 'Avbryt',
          icon: 'close',
        },
      },
    });

    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (confirm) => {
        if (confirm) {
          this.sharedservice.isLoading.next([
            true,
            'Vennligst vent...',
            [
              `Sletter ${this.selectedRows.length} sjekklister...`
            ],
          ]);

          for (const checklist of this.selectedRows) {
            await this.checklistservice.deleteChecklist(checklist, false);
          }

          this.sharedservice.isLoading.next([false, '', [], []]);
          this.snackbarservice.openSnackBar(
            `${this.selectedRows.length} sjekklister ble slettet`,
            ''
          );
          this.selectedRows = [];
        }
      });

  }

}
