import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, take, takeUntil} from 'rxjs/operators';
import {Currentpath} from 'src/app/models/Currentpath';
import {Project} from 'src/app/models/Project';
import {User} from 'src/app/models/User';
import {AdminService} from 'src/app/services/admin.service';
import {CurrentpathService} from 'src/app/services/currentpath.service';
import {EmailService} from 'src/app/services/email.service';
import {IdService} from 'src/app/services/id.service';
import {ProjectService} from 'src/app/services/project.service';
import {SharedService} from 'src/app/services/shared.service';
import {UserService} from 'src/app/services/user.service';
import {environment} from 'src/environments/environment';
import {InvalidProjectComponent} from '../popups/invalid-project/invalid-project.component';
import {HttpClient} from '@angular/common/http';
import {SortService} from '../../services/sort.service';
import {PopupService} from '../../services/popup.service';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {SnackbarService} from '../../services/snackbar.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnDestroy, OnInit {
  appVersion: string;
  newVersion: string;
  isShowingUpdatePopup: boolean;
  // private ngUnsubscribeUsers = new Subject();
  versionMessage: string;
  showingChangelogPopup = false;
  userNotFound = false;
  userFromFirebase: any;
  updateuseronce = true;
  updatealertsonce = true;
  alert: string;
  isAdmin = false;
  user: User;
  maintenance: any;
  ismaintenance = false;
  maintenanceheader: string;
  maintenancetext: string;
  maintenanceallowsuperadmin: boolean;
  oobCode: string;
  isPasswordReset = false;
  isAuthenticated = '';
  isLoading = true;
  loadingmessage = '';
  currentPath: Currentpath;
  url: string;
  mobileQuery: MediaQueryList;
  projectid: string;
  project: Project;
  projects: Project[];
  googlemapsApiLoaded: Observable<boolean>;
  isLoadingShared = [false, '', ''];
  private ngUnsubscribe = new Subject();
  private ngUnsubscribeUser = new Subject();
  private ngUnsubscribeProjects = new Subject();
  private mobileQueryListener: () => void;

  isProductionEnvironment = environment.production;

  constructor(
    private idservice: IdService,
    private projectservice: ProjectService,
    private currentpathservice: CurrentpathService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private dialog: MatDialog,
    // locationservice: LocationService,
    private adminservice: AdminService,
    private authservice: AuthService,
    private userservice: UserService,
    private sharedservice: SharedService,
    private emailservice: EmailService,
    private sortservice: SortService,
    private popupservice: PopupService,
    updates: SwUpdate,
    httpClient: HttpClient,
    private snackbarservice: SnackbarService,
    private activatedRoute: ActivatedRoute,
  ) {

    setInterval(function myTimer() {
      updates.checkForUpdate();
    }, 60000);

    updates.versionUpdates.subscribe((event: VersionReadyEvent) => {
      if (event.currentVersion?.hash !== event.latestVersion?.hash) {
        this.popupservice.openUpdateDialog();
        // console.log('current version is', event.currentVersion);
        // console.log('latest version is', event.latestVersion);
      }
      // console.log('Serviceworker event: ', event);
    });

    this.googlemapsApiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=' + environment.mapsapikey,
        'callback'
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users) => {
        if (users !== null && users !== undefined && users.length > 0) {
          this.user = users[0];
          if (this.url) {
            this.checkAccessToCurrentPage();
          }
          if (this.user?.role?.name !== 'customer') {
            // locationservice
            //   .getPosition()
            //   .then(() => {
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
          }
        }
      });

    this.currentPath = this.currentpathservice.getCurrentpath();

    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthLarge);
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart && (e.navigationTrigger === 'popstate' || e.navigationTrigger === 'imperative')),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: NavigationStart) => {
        this.url = event.url;
        this.checkIfPasswordReset();
        this.checkAccessToCurrentPage();
      });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: NavigationEnd) => {
        this.currentPath.event = event;
        this.projectid = this.idservice.projectId();
        if (this.projectid) {
          this.loadProject();
        } else {
          this.sharedservice.objects.next(null);
          this.sharedservice.project.next(null);
          this.sharedservice.checklists.next(null);
          this.sharedservice.receiptforms.next(null);
          // this.sharedservice.objecttypes.next(null);
          // this.sharedservice.checklistitems.next(null);
          this.sharedservice.checklistpdfs.next(null);
          // this.sharedservice.checklistfiles.next(null);
          this.sharedservice.checklistsandreceiptforms.next(null);
          this.project = null;
        }
      });

    this.adminservice
      .getAllChecklistitemsObs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((itemTypes) => {
        this.sharedservice.checklistitemtypes.next(itemTypes);
      });
    this.adminservice
      .getAllObjecttypesObs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((types) => {
        const typesSorted = types.sort(this.sortservice.dynamicSort('name'));
        this.sharedservice.objecttypes.next(typesSorted);
      });
  }

  authenticateUser(user: User) {
    if (user) {
      this.userNotFound = false;
      this.projectid = this.idservice.projectId();

      this.user = user;
      this.showingChangelogPopup = this.appVersion !== this.user?.appVersion;

      if (this.user?.role?.name === 'admin') {
        this.isAdmin = true;
      }
      if (this.updateuseronce) {
        this.updateuseronce = false;
        this.userservice.updateUserLastseen(this.user);
      }
      this.isAuthenticated = 'true';
      this.loadProject();
      if (this.maintenance?.ismaintenance) {
        this.maintenanceheader = this.maintenance.maintenanceheader;
        this.maintenancetext = this.maintenance.maintenancetext;
        this.ismaintenance = true;
      } else if (!this.user?.active) {
        this.maintenanceheader = 'Bruker deaktivert';
        this.maintenancetext =
          'Ta kontakt med APG teamet på ' +
          environment.supportemail +
          ' dersom du mener dette er en feil';
        this.ismaintenance = true;
      } else {
        this.ismaintenance = false;
        this.maintenanceheader = null;
        this.maintenancetext = null;
      }
    }
  }

  setupMaintenance() {
    if (
      this.user?.superAdmin === this.maintenance.allowsuperadmin &&
      this.maintenance.ismaintenance
    ) {
      this.ismaintenance = false;
    }
    if (this.maintenance.ismaintenance && this.user?.active) {
      this.maintenanceheader = this.maintenance.maintenanceheader;
      this.maintenancetext = this.maintenance.maintenancetext;
      this.maintenanceallowsuperadmin = this.maintenance.allowsuperadmin;
    } else if (this.user?.active) {
      this.maintenanceheader = null;
      this.maintenancetext = null;
      this.maintenanceallowsuperadmin = false;
    }
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.oobCode = params.oobCode;
        this.checkIfPasswordReset();
      });
    this.sharedservice.isLoading
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isloading: any[]) => {
        if (isloading !== null) {
          this.isLoadingShared = isloading;
        }
      });
    this.userservice
      .getCurrentUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (firebaseUser) => {
        this.userFromFirebase = firebaseUser;
        if (firebaseUser) {
          await this.authservice.checkUserAndUpdateIfMatching(firebaseUser);
          this.buildProjects();
        } else {
          console.log('Ingen adgang...');
          this.isAuthenticated = 'false';
          this.isLoading = false;
        }
      });

    this.appVersion = environment.appVersion;
    console.log('APG v.' + this.appVersion);

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.authenticateUser(u[0]);
        }
      });
    this.userservice
      .getUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (users: User[]) => {
        if (users !== null && users !== undefined && users.length > 0) {
          this.sharedservice.users.next(users);
        }
      });
  }

  buildProjects() {
    if (this.user) {
      console.log('Building projects...');

      this.ngUnsubscribeProjects.next(null);
      this.ngUnsubscribeProjects.complete();

      this.projectservice
        .getAllShortProjects(this.user)
        .pipe(takeUntil(this.ngUnsubscribeProjects))
        .subscribe((projects) => {
          if (projects !== null) {
            this.projects = projects;
            this.sharedservice.projects.next(projects);
            if (this.updatealertsonce) {
              this.updatealertsonce = false;
              this.buildAlerts();
            }
          }
        });
    }
  }

  buildAlerts() {
    console.log('Building alerts...');
    this.adminservice
      .getAppAlert()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((alert: any) => {
        if (alert !== null && alert !== undefined) {
          this.sharedservice.alert.next(alert);
          if (alert.isalert) {
            this.alert = alert.text;
          } else {
            this.alert = null;
          }
        }
      });
    this.adminservice
      .getAppMaintenance()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((maintenance: any) => {
        if (maintenance !== null && maintenance !== undefined) {
          this.sharedservice.maintenance.next(maintenance);
          this.maintenance = maintenance;
          this.ismaintenance = maintenance.ismaintenance;
          this.setupMaintenance();
        }
      });
    this.sharedservice.versionMessage
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((vm) => {
        if (vm !== null) {
          this.sharedservice.newAppVersion
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((newAppVersion) => {
              if (newAppVersion !== null) {
                this.newVersion = newAppVersion[0];
                this.versionMessage = vm[0];
                const dia = this.dialog.getDialogById('update-popup');
                if (this.versionMessage) {
                  if (!dia && !this.isShowingUpdatePopup) {
                    this.isShowingUpdatePopup = true;
                    // this.popupservice.openUpdateDialog(this.versionMessage, this.newVersion);
                  }
                } else {
                  this.versionMessage = null;
                  if (dia) {
                    dia.close();
                  }
                }
              }
            });
        }
      });
  }


  ngOnDestroy() {
    this.ngUnsubscribeUser.next(null);
    this.ngUnsubscribeUser.complete();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.sharedservice.isLoading.complete();
    this.sharedservice.objects.complete();
    this.sharedservice.checklists.complete();
    this.sharedservice.receiptforms.complete();
    // this.sharedservice.objecttypes.complete();
    // this.sharedservice.checklistitems.complete();
    this.sharedservice.checklistpdfs.complete();
    // this.sharedservice.checklistfiles.complete();
    this.sharedservice.checklistsandreceiptforms.complete();
  }

  loadProject() {
    if (this.isAuthenticated === 'true') {
      this.projectid = this.idservice.projectId();
      if (this.projectid) {
        this.projectservice
          .getFullProjectById(this.projectid)
          .pipe(take(1))
          .subscribe((p) => {
            if (!this.project?.id && this.projectid) {
              this.project = p;
              if (this.url) {
                this.checkAccessToCurrentPage();
              }
              this.sharedservice.project.next(p);
              this.sharedservice.project
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((project) => {
                  if (project !== null) {
                    this.ngUnsubscribeUser.next(null);
                    this.ngUnsubscribeUser.complete();
                    this.projectid = this.idservice.projectId();
                    this.project = project;
                    if (!project) {
                      this.openDialogProjectInvalid();
                      this.isLoading = false;
                    } else {
                      if (project.id) {
                        this.sharedservice.user
                          .pipe(takeUntil(this.ngUnsubscribeUser))
                          .subscribe((u) => {
                            if (u !== null) {
                              let accescheck = false;
                              u[0].projectaccess.forEach((access, index) => {
                                if (access.id === this.projectid) {
                                  accescheck = true;
                                  const dialogExist =
                                    this.dialog.getDialogById(
                                      'no-access-popup'
                                    );
                                  if (dialogExist) {
                                    this.dialog
                                      .getDialogById('no-access-popup')
                                      .close();
                                  }
                                  this.isLoading = false;
                                }
                                if (
                                  !accescheck &&
                                  index === u[0].projectaccess.length - 1 &&
                                  this.projectid
                                ) {
                                  this.openDialogProjectNoAccess();
                                }
                              });
                            }
                          });
                      }
                    }
                  }
                });
            }
          });
      } else {
        this.project = null;
        this.isLoading = false;
      }
    } else {
    }
  }

  openDialogProjectInvalid() {
    this.dialog.open(InvalidProjectComponent, {
      closeOnNavigation: true,
      disableClose: true,
      backdropClass: 'blur-dialog-backdrop-hard',
    });
  }

  openDialogProjectNoAccess() {
    const dialogExist = this.dialog.getDialogById('no-access-popup');
    if (!dialogExist) {
      this.dialog.open(InvalidProjectComponent, {
        id: 'no-access-popup',
        closeOnNavigation: true,
        disableClose: true,
        backdropClass: 'blur-dialog-backdrop-hard',
        data: {
          header: 'Ingen adgang!',
          text: 'Du har ikke tilgang til dette prosjektet',
        },
      });
    }
  }

  approveChangelog() {
    this.showingChangelogPopup = false;
    this.userservice.updateUserAppversion(this.user);
  }

  sendEmail() {
    const subject = 'APG - Bruker ikke funnet'; // message.subject;
    const body =
      'Hei, min bruker ble ikke funnet ved pålogging på APG. Hilsen ' +
      this.userFromFirebase.displayName +
      ', ' +
      this.userFromFirebase.email;
    this.emailservice.sendEmail(environment.supportemail, subject, body);
  }

  allowsuperadminDuringMaintenance() {
    this.adminservice.setAppMaintenanceAllowsuperadmin(true).then(() => {
    });
  }

  checkIfPasswordReset() {
    if (this.url?.includes('/reset-password?')) {
      if (this.oobCode) {
        this.isPasswordReset = true;
      }
    } else {
      this.isPasswordReset = false;
    }
  }

  checkAccessToCurrentPage() {
    if (this.user && this.url && this.url !== '/') {
      const urlPath = this.url?.split('/');
      const mainPath = urlPath[1];
      const subPath = urlPath[3];
      const subSubPath = urlPath[5];
      // Project access rights
      if (this.project) {
        if (this.user.role?.name === 'customer') {
          let accesGranted = false;
          this.user.projectaccess.forEach((projectaccess) => {
            if (projectaccess.id === this.project.id) {
              accesGranted = true;
            }
          });
          if (accesGranted) {
            if (
              !mainPath ||
              mainPath === '' ||
              (mainPath === 'm' && !subPath) ||
              (mainPath === 'p' && !subPath) ||
              (mainPath === 'p' && subPath === 'p') ||
              (mainPath === 'p' && subPath === 'sl') ||
              (mainPath === 'p' && subPath === 'o' && subSubPath === 's')
            ) {
              // Customer has access to this module
            } else {
              this.snackbarservice.openSnackBar(
                'Du har ikke tilgang til denne modulen. Omdirigerer til sjekklister',
                ''
              );
              this.router.navigateByUrl('/p/' + this.project.id + '/sl');
            }
          } else {
            console.log('customer does not have access to this project');
            this.snackbarservice.openSnackBar(
              'Du har ikke tilgang til ' +
              this.project.id +
              ' - ' +
              this.project.name,
              ''
            );
            this.router.navigateByUrl('/');
          }
        } else {
        }
      }
      // Global access rights
      else if (this.user.role?.name === 'user' || this.user.role?.name === 'customer') {
        if (mainPath === 'a') {
          this.snackbarservice.openSnackBar(
            'Du har ikke tilgang til denne modulen. Omdirigerer til prosjektliste',
            ''
          );
          this.router.navigateByUrl('/');
        } else {
        }
      }
    }
  }
}
