import {MediaMatcher} from '@angular/cdk/layout';
import {Location} from '@angular/common';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ActivatedRoute, NavigationStart, Params, Router} from '@angular/router';
import {firstValueFrom, Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {ChecklistService} from 'src/app/services/checklist.service';
import {DateService} from 'src/app/services/date.service';
import {IdService} from 'src/app/services/id.service';
import {PopupService} from 'src/app/services/popup.service';
import {PrintChecklistService} from 'src/app/services/print-checklist.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import {ObjectchecklistsComponent} from '../../checklists/objectchecklists/objectchecklists.component';
import {CanDeactivateComponent} from '../../common/can-deactivate/can-deactivate.component';
import {NewObjectComponent} from '../new-object/new-object.component';
import {NewObjectsMultipleComponent} from '../new-objects-multiple/new-objects-multiple.component';
import {ExcelService} from 'src/app/services/excel.service';
import {Table} from 'primeng/table';
import {FilterMetadata, SortMeta} from 'primeng/api';
import {SortButton} from 'src/app/models/PrimeNG';
import {TableService} from 'src/app/services/prime/table.service';
import {AdminService} from 'src/app/services/admin.service';
import * as _ from 'underscore';
import {ObjectStatus} from 'src/app/models/Status';
import {Project} from 'src/app/models/Project';
import {ObjectService} from '../../../services/object.service';
import {ConfirmComponent} from '../../common/bottom-sheet/confirm/confirm.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {SnackbarService} from '../../../services/snackbar.service';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.css'],
})
export class ObjectListComponent
  extends CanDeactivateComponent
  implements OnInit, OnDestroy, AfterViewInit {
  disableRoute = false;
  viewInitialized = false;
  isImportingObjects = false;
  objects: AventiObject[];
  objectsFrontend: AventiObject[];
  objectsTemp: AventiObject[];
  checklists: Checklist[];
  checklistitems: ChecklistItem[];
  checklistfiles: AventiFile[];
  checklistpdfs: AventiFile[];
  archivedVisible = false;
  numberOfArchived = 0;
  objectsReady: boolean;
  isEmpty = false;
  // checklists: Checklist[];
  projectid: string;
  user: User;
  isLoading = true;
  isOnline: boolean;
  isGeneratingPDF = false;

  dataSource: MatTableDataSource<AventiObject>; // = new MatTableDataSource();
  pageIndex = 0;
  pageSize = 25;
  page = 1;
  // objectid: string;
  pages: number;
  params: Params;
  // isSort: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  mobileQuery: MediaQueryList;
  filter = '';
  sortactive = '';
  sortdirection: 'asc' | 'desc';
  runonce = true;
  FormFilter = new UntypedFormGroup({
    filter: new UntypedFormControl('', []),
  });
  selectedRows: AventiObject[] = [];
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  tableFiltersReady = false;
  pauseSorting = false;
  resetTable = false;
  numberOfTableSorts = 0;
  numberOfTableFilters = 0;
  tableSort: SortMeta[];
  tableFilters: { [p: string]: FilterMetadata } = {};
  sortButtons: SortButton[] = [
    {name: 'Objekt', sort: {field: 'nameSearchString', order: 0}},
    {name: 'Prosess', sort: {field: 'process', order: 0}},
    {name: 'Type', sort: {field: 'typename', order: 0}},
    {name: 'Profilnummer', sort: {field: 'profilenumber', order: 0}},
    // {name: 'Status', sort: {field: 'status', order: 0}},
    {name: 'Tags', sort: {field: 'tagsSearchString', order: 0}},
    {name: 'Sjekkliste', sort: {field: 'checklistPresent', order: 0}}
  ];
  filterFields: string[] = [];
  displayedColumns: string[] = [];
  searchString = '';
  statuses: ObjectStatus[] = [];
  statusNames: string[] = [];
  statusCount: any[] = [];
  processes: any[] = [];
  comments: any[] = [];
  types: any[] = [];
  profilenumbers: any[] = [];
  isAdmin: boolean;
  isSuperAdmin: boolean;
  project: Project;
  @ViewChild('dataTable') dt: Table;
  // isPaginator: boolean;
  private ngUnsubscribe = new Subject();
  // private ngUnsubscribeObjects = new Subject();
  private ngUnsubscribeChecklists = new Subject();
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private ngUnsubscribeChecklistPdfs = new Subject();
  // private readonly mobileQueryListener: () => void;
  private ngUnsubscribeObjectsShared = new Subject();
  private ngUnsubscribeChecklistsShared = new Subject();
  private ngUnsubscribeChecklistPdfsShared = new Subject();

  constructor(
    private idservice: IdService,
    media: MediaMatcher,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedservice: SharedService,
    private printchecklistservice: PrintChecklistService,
    private checklistservice: ChecklistService,
    private objectservice: ObjectService,
    private dateservice: DateService,
    private popupservice: PopupService,
    private dialogObjectChecklists: MatDialog,
    private location: Location,
    private dialogNewObject: MatDialog,
    private excelservice: ExcelService,
    private dialog: MatDialog,
    private ptableService: TableService,
    private adminservice: AdminService,
    private bottomSheet: MatBottomSheet,
    private snackbarservice: SnackbarService,
  ) {
    super();
    this.projectid = this.idservice.projectId();
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    this.adminservice
      .getObjectStatuses()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((statuses) => {
        this.statuses = _.reject(statuses, _.isNull);
        this.statuses = _.sortBy(this.statuses, 'priority');
        this.statusNames = this.statuses.map(status => this.user.language?.id === 'en' ? status.enName : status.name);
        this.isLoading = false;
        if (statuses !== null) {
          this.buildObjects();
        }
      });
  }

  get desc() {
    return this.FormFilter.get('filter');
  }

  canDeactivate(): boolean {
    return !this.isGeneratingPDF;
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.objects;
    }
    // tslint:disable-next-line:only-arrow-functions
    this.dt.resetScrollTop = function() {
    };
  }

  async ngOnInit(): Promise<void> {
    this.isOnline = await firstValueFrom(this.sharedservice.online);
    this.sharedservice.project
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((project) => {
        if (project !== null) {
          this.project = project;
        }
      });

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          this.isAdmin = user[0].role?.name === 'admin';
          this.isSuperAdmin = user[0].superAdmin;
        }
      });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(async () => {
        if (!this.resetTable) {
          this.callSetTableSortAndFilterFromQueryParams();
        }
      });

    this.objects = null;
    this.checklists = null;
    this.checklistpdfs = null;
    this.isLoading = true;
    this.objectsReady = false;

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          this.user = user[0];
        }
      });
    if (this.runonce) {
      // this.getPagesOnLoad();
      this.runonce = false;
    }

    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribeObjectsShared))
      .subscribe((objects: AventiObject[]) => {
        this.objectsTemp = objects;
        if (objects !== null) {
          this.buildObjects();
        }
      });

  }

  newObject() {
    this.dialogNewObject.open(NewObjectComponent, {
      backdropClass: 'blur-dialog-backdrop',
    });
  }

  downloadTemplateForMultipleObjects() {
    this.excelservice.exportAsExcelFile(
      [
        {
          name: '',
          process: '',
          type: '',
          desc: '',
          profilenumber: '',
          drawing: '',
          comment: '',
        },
      ],
      'importmal'
    );
  }

  downloadObjectsToExcel() {
    const objectsForExport = [];
    this.sharedservice.objects.pipe(take(1)).subscribe((o: AventiObject[]) => {
      this.checklistservice.getChecklistFiles(this.projectid)
        .pipe(take(1))
        .subscribe((f: AventiFile[]) => {
          o.forEach((object) => {
            object.checklists?.forEach((checklist) => {
              f.forEach((file) => {
                if (
                  file.checklistid === checklist.id &&
                  (!file.checklistitemid || file.checklistitemid === '')
                ) {
                  if (!checklist.files) {
                    checklist.files = [];
                  }
                  checklist.files.push(file);
                }
              });
            });
          });
          console.log(o);
          o.forEach((object) => {
            const checklistIds = [];
            const checklistStatus = [];
            const checklistPdfids = [];
            const checklistPdfs = [];
            const checklistImageIds = [];
            const checklistImages = [];
            const checklistImageLat = [];
            const checklistImageLng = [];
            object.checklists?.forEach((checklist) => {
              checklistIds.push(checklist.id);
              checklistStatus.push(checklist.status);
              checklist.pdfexports?.forEach((pdf) => {
                checklistPdfids.push(pdf.id);
                checklistPdfs.push(pdf.downloadurl);
              });
              checklist.files?.forEach((file) => {
                checklistImageIds.push(file.id);
                checklistImages.push(file.downloadurl);
                checklistImageLat.push(file.exif?.latitude || 'null');
                checklistImageLng.push(file.exif?.longitude || 'null');
              });
            });
            objectsForExport.push({
              objectid: object.id,
              name: object.name,
              process: object.process,
              type: object.type?.name,
              desc: object.desc,
              profilenumber: object.profilenumber,
              drawing: object.drawing,
              comment: object.comment,
              checklist_ids: checklistIds.join(),
              checklist_count: checklistIds.length,
              checklist_status: checklistStatus.join(),
              checklist_pdfids: checklistPdfids.join(),
              checklist_pdf_count: checklistPdfids.length,
              checklist_pdfs: checklistPdfs.join(),
              checklist_image_ids: checklistImageIds.join(),
              checklist_image_count: checklistImageIds.length,
              checklist_images: checklistImages.join(),
              checklist_image_lat: checklistImageLat.join(),
              checklist_image_lng: checklistImageLng.join(),
            });
          });
          console.log(objectsForExport);
          this.excelservice.exportAsExcelFile(objectsForExport, 'objects');
        });
    });
  }

  downloadObjectUrlsToExcel() {
    const objectsForExport = [];
    const baseUrl = environment.baseURL;
    this.sharedservice.objects.pipe(take(1)).subscribe((o: AventiObject[]) => {
      console.log(o);
      o.forEach((object) => {
        objectsForExport.push({
          name: object.name,
          desc: object.desc,
          profilenumber: object.profilenumber,
          url: baseUrl +
            'p/' +
            object.projectid +
            '/o/' +
            object.id
        });
      });
      console.log(objectsForExport);
      this.excelservice.exportAsExcelFile(objectsForExport, 'objecturls');
    });
  }

  uploadMultipleObjects() {
    document.getElementById('objectimportinput').click();
  }

  dataFromExcelEventEmitter(data) {
    this.isImportingObjects = true;
    const dialogRef = this.dialog.open(NewObjectsMultipleComponent, {
      closeOnNavigation: true,
      disableClose: false,
      width: '100%',
      backdropClass: 'blur-dialog-backdrop',
    });
    dialogRef.componentInstance.data = data;
    dialogRef.afterClosed().subscribe((res) => {
      console.log('closed', res);
      this.isImportingObjects = false;
      this.buildObjects();
    });
  }

  buildObjects() {
    if (
      this.objectsTemp &&
      this.statuses
    ) {

      if (this.objectsTemp.length === 0) {
        this.isEmpty = true;
        this.displayedColumns = [];
      } else {
        this.isEmpty = false;
        this.setDisplayedColumns(this.mobileQuery.matches);
      }
      this.numberOfArchived = 0;
      this.objects = [];
      this.objectsTemp.forEach((object) => {
        object.typename = object.type?.name;
        object.nameSearchString = object.name + object.desc + object.comment;
        object.statusSearchString = this.user.language?.id === 'en' ? object.status?.enName : object.status?.name;
        let tagsSearchString = '';
        object.tags?.map(tag => {
          tagsSearchString += tag.key + tag.value;
        });
        object.tagsSearchString = tagsSearchString;

        const newProcess = {
          label: object.process,
          value: object.process
        };
        if (object.process?.length > 0 &&
          !this.processes.some(item => item.label === object.process || item.value === object.process)) {
          this.processes.push(newProcess);
        }

        const newComment = {
          label: object.comment,
          value: object.comment
        };
        if (object.comment?.length > 0 &&
          !this.comments.some(item => item.label === object.comment || item.value === object.comment)) {
          this.comments.push(newComment);
        }

        const newType = {
          label: object.type.name,
          value: object.type.name
        };
        if (object.type?.name?.length > 0 &&
          !this.types.some(item => item.label === object.type.name || item.value === object.type.name)) {
          this.types.push(newType);
        }

        const newProfileNumber = {
          label: object.profilenumber,
          value: object.profilenumber
        };
        if (object.profilenumber?.length > 0 &&
          !this.profilenumbers.some(item => item.label === object.profilenumber || item.value === object.profilenumber)) {
          this.profilenumbers.push(newProfileNumber);
        }

        object.checklistPresent = (object.checklists && object.checklists.length > 0);

        if (!object.isarchived) {
          this.objects.push(object);
        } else {
          this.numberOfArchived++;
          if (this.archivedVisible) {
            this.objects.push(object);
          }
        }
      });

      if (!this.dataSource) {
        this.fillDataSource(this.objectsTemp);
      }
      this.dataSource.data = this.objects;
      if (!this.isImportingObjects) {
        this.objectsFrontend = structuredClone(this.objectsTemp);
      }
    }
    this.statusCount = [];
    this.statuses.forEach(status => {
      this.statusCount.push({
        ...status,
        count: this.objectsTemp ? this.objectsTemp.filter(o => o.status?.name === status.name).length : 0
      });
    });
  }

  fillDataSource(objects: AventiObject[]) {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource(objects);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (
      data,
      filterInput: string
    ): boolean => {
      const filterLowerCase = filterInput.toLowerCase();
      return (
        data.id.toLowerCase().includes(filterLowerCase) ||
        data.name.toLowerCase().includes(filterLowerCase) ||
        data.type?.name?.toLowerCase().includes(filterLowerCase) ||
        data.profilenumber?.toString()?.toLowerCase().includes(filterLowerCase) ||
        data.process?.toString()?.toLowerCase().includes(filterLowerCase) ||
        data.desc?.toLowerCase().includes(filterLowerCase) ||
        data.lastupdatedString?.toString().includes(filterLowerCase) ||
        (data.tags && JSON.stringify(data.tags).toLowerCase().includes(filterLowerCase))
      );
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastupdated': {
          if (item.lastupdated) {
            return this.dateservice.dateAsYYYYMMDDHHNNSS(item.lastupdated);
          } else {
            return null;
          }
        }
        case 'type': {
          if (item.type) {
            return item.type.name;
          } else {
            return null;
          }
        }
        case 'mobile': {
          let searchstring = '';
          if (item.type.name) {
            searchstring += item.type.name;
          }
          if (item.name) {
            searchstring += item.name;
          }
          if (item.profilenumber) {
            searchstring += item.profilenumber;
          }
          return searchstring;
        }
        case 'checklists': {
          if (item.checklists && item.checklists.length > 0) {
            return item.checklists[0].status;
          } else {
            return null;
          }
        }
        case 'checklistpdfs': {
          if (item.checklists && item.checklists.length > 0) {
            if (
              item.checklists[0].pdfexports &&
              item.checklists[0].pdfexports.length > 0
            ) {
              return 'pdf' + item.checklists[0].pdfexports[0].name;
            } else {
              return item.checklists[0].status;
            }
          } else {
            return 'zzzzzzzzzz';
          }
        }
        case 'deliveredToCustomer': {
          if (item.checklists && item.checklists.length > 0) {
            if (
              item.checklists[0].PDFDeliveredToCustomer &&
              item.checklists[0].PDFDeliveredToCustomer.status
            ) {
              return 3;
            } else if (item.checklists[0].status === 'signed') {
              return 2;
            } else {
              return 1;
            }
          } else {
            return 0;
          }
        }
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.objectsReady = true;

    const dataLength = objects?.length;
    if (dataLength === 0) {
      this.isEmpty = true;
      this.displayedColumns = [];
    } else {
      this.isEmpty = false;
      this.setDisplayedColumns(this.mobileQuery.matches);
    }
    this.getFilterOnLoad();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.sortactive = params.sortactive;
      this.sortdirection = params.sortdirection;
      if (!this.sortactive?.includes('checklists')) {
        this.setSortingOnLoad();
      }
    });
    // this.getPagesOnLoad();
    this.pages = Math.ceil(dataLength / this.pageSize);
    this.isLoading = false;
    this.callSetTableSortAndFilterFromQueryParams();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.ngUnsubscribeChecklists.next(null);
    this.ngUnsubscribeChecklists.complete();
    this.ngUnsubscribeChecklistPdfs.next(null);
    this.ngUnsubscribeChecklistPdfs.complete();
    this.ngUnsubscribeObjectsShared.next(null);
    this.ngUnsubscribeObjectsShared.complete();
    this.ngUnsubscribeChecklistsShared.next(null);
    this.ngUnsubscribeChecklistsShared.complete();
    this.ngUnsubscribeChecklistPdfsShared.next(null);
    this.ngUnsubscribeChecklistPdfsShared.complete();
  }

  setSorting(sortBy) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        sortactive: sortBy.active,
        sortdirection: sortBy.direction,
      },
      queryParamsHandling: 'merge',
    });
  }

  setSortingOnLoad() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.sortactive = params.sortactive;
      this.sortdirection = params.sortdirection;
      if (
        this.sortactive?.length > 0 &&
        this.sort?.active !== this.sortactive &&
        this.sort?.direction !== this.sortdirection
      ) {
        this.sort.sort({
          id: this.sortactive,
          start: this.sortdirection,
          disableClear: false,
        });
      }
    });
  }

  getFilterOnLoad() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.filter = params.filter;
      // document.getElementById('filter').nodeValue = filter;
      this.FormFilter.patchValue({
        filter: this.filter,
      });
      if (this.filter && this.filter?.length > 0) {
        // this.applyFilter(null, this.filter);
      }
    });
  }

  setFilter(filterInput: string) {
    if (this.objects) {
      this.applyFilter(null, filterInput);
    }
  }

  applyFilter(event?: Event, value?: string) {
    let filterValue: string;
    if (event) {
      filterValue = (event.target as HTMLInputElement).value;
    } else {
      filterValue = value;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.FormFilter.patchValue({
      filter: filterValue,
    });

    this.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pages = Math.ceil(
      this.dataSource?.filteredData?.length / this.pageSize
    );
    this.page = 1;

    let pathWithNewParams = this.location.path().split('?')[0] + '?';
    pathWithNewParams += 'filter=' + filterValue;
    pathWithNewParams += '&';
    pathWithNewParams += 'pageIndex=' + (this.page - 1);
    this.location.replaceState(pathWithNewParams);
  }

  pageChange(page) {
    this.page = this.pageIndex + 1;
    this.pages = Math.ceil(page.length / page.pageSize);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {pageIndex: page.pageIndex, pageSize: page.pageSize},
      queryParamsHandling: 'merge',
    });
  }

  getPagesOnLoad() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.params = params;
        if (params.pageIndex) {
          this.pageIndex = parseInt(params.pageIndex, 10);
          if (this.pageIndex < 0) {
            this.pageIndex = 0;
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
              },
              queryParamsHandling: 'merge',
            });
          }
          this.page = this.pageIndex + 1;
        }
        if (params.pageSize) {
          this.pageSize = params.pageSize;
        }
        if (params.archivedVisible) {
          this.archivedVisible = params.archivedVisible === 'true';
        }
      });
    this.pages = Math.ceil(
      this.dataSource?.filteredData?.length / this.pageSize
    );
    if (this.page > this.pages) {
      this.page = this.pages;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {pageIndex: this.page - 1, pageSize: this.pageSize},
        queryParamsHandling: 'merge',
      });
    }
  }

  setDisplayedColumns(matches) {
    if (matches) {
      this.displayedColumns = [
        'mobile',
        'checklists',
      ];
    } else {
      this.displayedColumns = [
        'process',
        'type',
        'name',
        'desc',
        'profilenumber',
        'tags',
        'comment',
        'checklists',
        'lastupdated',
      ];
    }
    this.filterFields = this.displayedColumns;
    this.filterFields.push('nameSearchString', 'checklistPresent', 'typename', 'statusSearchString');
  }

  toggleArchivedVisibility() {
    this.archivedVisible = !this.archivedVisible;
    this.buildObjects();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {archivedVisible: this.archivedVisible},
      queryParamsHandling: 'merge',
    });
  }

  generatePDF(checklist: Checklist) {
    this.isGeneratingPDF = true;
    this.printchecklistservice.sendChecklistToPrint(checklist);
  }

  generatePDFresetError(checklist: Checklist) {
    this.printchecklistservice.generatePdfResetError(checklist);
  }

  setDeliveredToCustomer(checklist: Checklist) {
    let s = true;
    if (checklist.PDFDeliveredToCustomer) {
      if (checklist.PDFDeliveredToCustomer.status) {
        s = false;
      }
    }
    checklist.PDFDeliveredToCustomer = {
      status: s,
    };
    this.checklistservice.updateChecklistDeliveredPdfToCustomer(
      s,
      checklist,
      this.user
    );
  }

  openDialogImagePreview(file: AventiFile, checklist: Checklist) {
    this.popupservice.openDialogFilePreview([file], 0, false, checklist, this.params);
  }

  openDialogObjectChecklists(object: AventiObject) {
    this.toggleDisableRoute();
    const path = this.location.path();
    const dialogRef: MatDialogRef<ObjectchecklistsComponent> =
      this.dialogObjectChecklists.open(ObjectchecklistsComponent, {
        closeOnNavigation: true,
        disableClose: false,
        width: '100%',
        backdropClass: 'blur-dialog-backdrop',
      });
    dialogRef.afterOpened().subscribe(() => {
      this.location.replaceState(path);
    });
    dialogRef.componentInstance.object = object;
    dialogRef.componentInstance.popup = true;
  }

  toggleDisableRoute() {
    this.disableRoute = true;
    setTimeout(() => this.disableRoute = false, 100);
  }


  onSort(event) {
    if (this.tableFiltersReady && !this.pauseSorting && !this.resetTable) {
      const sortArray: SortMeta[] = event.multisortmeta;
      this.tableSort = sortArray;
      this.sortButtons =
        this.ptableService.onSort(sortArray, this.sortButtons, this.tableSort, this.tableFilters, this.tableFiltersReady);
    }
  }

  sortFromButton(button: SortButton) {
    this.pauseSorting = true;
    const {
      tableSort,
      numberOfTableSorts,
      sortButtons
    } = this.ptableService.sortFromButton(
      button,
      this.sortButtons,
      this.tableSort,
      this.tableFilters,
      this.tableFiltersReady,
      this.numberOfTableSorts
    );
    this.tableSort = tableSort;
    this.numberOfTableSorts = numberOfTableSorts;
    this.sortButtons = sortButtons;
    this.pauseSorting = false;
  }

  clearTable(table: Table) {
    this.resetTable = true;
    this.searchString = '';
    this.selectedRows = [];
    const {
      tableSort,
      numberOfTableFilters,
      numberOfTableSorts,
      sortButtons
    } = this.ptableService.clearTable(table, this.tableSort, this.sortButtons);
    this.tableSort = tableSort;
    this.numberOfTableFilters = numberOfTableFilters;
    this.numberOfTableSorts = numberOfTableSorts;
    this.sortButtons = sortButtons;
    this.resetTable = false;
  }

  resetTableSort() {
    const {
      tableSort,
      sortButtons,
      tableFilters,
      numberOfTableFilters,
      numberOfTableSorts,
      searchString
    } = this.ptableService.resetTableSort(
      this.tableSort,
      this.sortButtons,
      this.tableFilters,
      this.tableFiltersReady,
      this.tableFilters
    );
    this.tableSort = tableSort;
    this.sortButtons = sortButtons;
    this.tableFilters = tableFilters;
    this.numberOfTableFilters = numberOfTableFilters;
    this.numberOfTableSorts = numberOfTableSorts;
    this.searchString = searchString;
  }

  onFilter(event: any) {
    if (this.tableFiltersReady && !this.resetTable) {
      const eventFilters: { [p: string]: FilterMetadata } = event.filters;
      this.numberOfTableFilters = this.ptableService.setQueryParamsFromTableEvents(
        this.tableSort,
        this.tableFiltersReady,
        eventFilters
      );

      this.statusCount = [];
      this.statuses.forEach(status => {
        this.statusCount.push({
          ...status,
          count: this.objectsTemp.filter(o => o.status?.name === status.name && event.filteredValue.some(v => v.id === o.id)).length
        });
      });
    }
  }

  getSeverity(status: string) {
    return this.ptableService.getChecklistStatusSeverity(status);
  }

  onRowSelect(event: any) {
    if (this.mobileQuery.matches) {
      this.ptableService.openObject(event.data, this.disableRoute);
    }
  }

  onRowUnSelect(event: any) {
    if (!this.mobileQuery.matches && this.selectedRows.length === 0) {
      this.ptableService.openObject(event.data, this.disableRoute);
    }
  }

  toggleStatusFilter(name: string) {
    const newTableFilter = {...this.tableFilters};
    let newValue = newTableFilter.statusSearchString?.value ?? null;
    if (newValue?.includes(name)) {
      const index = newValue.indexOf(name);
      newValue.splice(index, 1);
      if (newValue.length === 0) {
        newValue = null;
      }
    } else {
      if (newValue === null) {
        newValue = [name];
      } else {
        newValue.push(name);
      }
    }
    if (newTableFilter.statusSearchString) {
      newTableFilter.statusSearchString.value = newValue;
    }
    if (newValue !== null) {
      newTableFilter.statusSearchString.operator = 'and';
    } else {
      delete newTableFilter.statusSearchString.operator;
    }
    this.dt.filter(newValue, 'statusSearchString', 'in');
    this.tableFilters = newTableFilter;
    this.dt.filters = newTableFilter;
  }

  async createMultipleChecklists() {

    this.sharedservice.isLoading.next([
      true,
      'Vennligst vent...',
      [
        `Oppretter ${this.selectedRows.length} sjekklister...`
      ],
    ]);

    for (const object of this.selectedRows) {
      await this.checklistservice.create(object, false, this.projectid, false);
    }

    this.sharedservice.isLoading.next([false, '', [], []]);
    this.selectedRows = [];
  }

  async deleteMultipleObjects() {

    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: `Slette objekter?`,
        text: `${this.selectedRows.length} objekter vil bli slettet.`,
        multiLine: ['Sjekklister tilhørende objektene vil også bli slettet.'],
        list: this.selectedRows.map((checklist) => checklist.name),
        yes: {
          name: 'Slett',
          icon: 'delete_forever',
        },
        no: {
          name: 'Avbryt',
          icon: 'close',
        },
      },
    });

    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (confirm) => {
        if (confirm) {
          this.sharedservice.isLoading.next([
            true,
            'Vennligst vent...',
            [
              `Sletter ${this.selectedRows.length} objekter og tilhørende sjekklister...`
            ],
          ]);

          for (const object of this.selectedRows) {
            await this.objectservice.deleteObject(object);
          }

          this.sharedservice.isLoading.next([false, '', [], []]);
          this.snackbarservice.openSnackBar(
            `${this.selectedRows.length} objekter og tilhørende sjekklister ble slettet.`,
            ''
          );
          this.selectedRows = [];
        }
      });
  }


  private callSetTableSortAndFilterFromQueryParams() {
    const result = this.ptableService.setTableSortAndFilterFromQueryParams(
      this.tableSort,
      this.sortButtons,
      this.tableFilters,
      this.tableFiltersReady
    );
    if (!result?.tableFiltersReady) {
      return setTimeout(() => {
        this.tableFiltersReady = true;
        this.callSetTableSortAndFilterFromQueryParams();
      }, 1000);
    } else if (result) {
      this.tableSort = result.tableSort;
      this.sortButtons = result.sortButtons;
      this.tableFilters = result.tableFilters;
      this.numberOfTableFilters = result.numberOfTableFilters;
      this.numberOfTableSorts = result.numberOfTableSorts;
      this.searchString = result.searchString;
    }
  }
}
